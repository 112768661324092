export const ACTIVE_COLOR = 'rgb(91, 91, 91)';
export const INACTIVE_COLOR = 'rgb(230, 230, 230)';
export const MARGIN_HORIZONTAL = 24;
export const LABEL_HEIGHT = 14;
export const LABELS = [
  100,
  1000,
  5000,
  10000,
  25000,
  100000,
  500000,
  1000000
];

export default {
  ACTIVE_COLOR,
  INACTIVE_COLOR,
  MARGIN_HORIZONTAL,
  LABEL_HEIGHT,
  LABELS,
};