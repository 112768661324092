const generateSteps = (data, numberOfSteps) => {
  const minX = Math.min(...data.map((_) => _.x));
  const maxX = Math.max(...data.map((_) => _.x));
  const range = maxX - minX;

  return new Array(numberOfSteps).fill(null).map((_, i) => {
    const step = (i * range) / (numberOfSteps - 1) + minX;
    
    const entry = data.find(entry => entry.x === step);
    if (entry) return entry;

    // Getting boundary
    const bounds = [[...data].reverse().find(entry => entry.x < step), [...data].find(entry => entry.x > step)];

    if (!bounds.every(_ => !!_)) return null;
    const x = step - bounds[0].x;
    const y = bounds[1].x - step;
  
    return { 
      x: step, 
      y: (x * bounds[1].y + y * bounds[0].y) / (x + y) 
    };
  });
};

export default generateSteps;