import React, { useEffect, useRef, useState } from 'react';
import { scaleLinear, scaleLog } from "d3-scale";
import * as d3 from 'd3';
import './_chart.scss'

// Components
import Slider from './Slider';
import Input  from './Input';

// Helopers
import config from './config';
import generateSteps from './generateSteps';
import generateLabel from './generateLabel';
import LogScale from 'log-scale';

global.logScale = LogScale;
// Getting constants
const { ACTIVE_COLOR, INACTIVE_COLOR, MARGIN_HORIZONTAL, LABEL_HEIGHT, LABELS } = config;

const Chart = ({ data = [], width = 340, height = 60, numberOfLabels = 5, range = [0, 0], onChange = () => {}, label = 'Headcount', onChangeEnd = () => {} }) => {
  const [localRange, setLocalRange] = useState([0, 0]);

  // Refs
  const _wrapper = useRef(null);
  const _axis = useRef(null);
  const _columns = useRef(null);
  const _timeout = useRef(null);
  
  // Dimensions
  const numberOfSteps = data.length;
  const relativeWidth = 1 / numberOfSteps;
  const innerHeight = height;
  const innerWidth = width - 2 * MARGIN_HORIZONTAL;
  const columnWidth = innerWidth / numberOfSteps;
  const rectWidth = columnWidth * 0.6;
  const rectOffset = (columnWidth - rectWidth) / 2;
  
  // Horizontal bounds
  const xMin = Math.min(...data.map(_ => _.x));
  const xMax = Math.max(...data.map(_ => _.x));

  // Generating the columns
  const value = generateSteps(data.map(entry => ({ ...entry, x: Math.log10(entry.x)})), numberOfSteps);
  
  // Vertical bounds
  const yMax = Math.max(...value.map(_ => _.y));

  // Range slider
  const rangeValue = range.map(val => val ? (Math.log10(val) - Math.log10(xMin)) / (Math.log10(xMax) - Math.log10(xMin)) : 0).map(e => Math.max(Math.min(e, 1), 0))
  const _handleSliderChange = (val) => {
    clearTimeout(_timeout.current);
    const range = val.map(e => Math.pow(10, e * (Math.log10(xMax) - Math.log10(xMin)) + Math.log10(xMin)));
    onChange(range);
    _timeout.current = setTimeout(() => _handleChangeEnd(range), 500);
  }

  // Scales
  const y = scaleLinear().domain([0, yMax]).range([0, height]);

  // Generate chart
  useEffect(() => {
    // Generate columns
    _columns.current = d3
      .select(_wrapper.current)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .selectAll('someRandomSelector')
      .data(data)
      .join('g')
      .append('rect')
        .attr('x', (d, i) => i * columnWidth + MARGIN_HORIZONTAL + rectOffset)
        .attr('y', d => innerHeight - y(d.y))
        .attr('width', rectWidth)
        .attr('height', d => y(d.y))
        .attr('fill', ACTIVE_COLOR);

    // Generate labels
    d3.select(_axis.current)
      .append('svg')
      .attr('width', width)
      .attr('height', LABEL_HEIGHT)
      .selectAll('someRandomSelector')
      .data([...LABELS.filter(label => label <= (xMax / 2) && label >= xMin), null])
      .join('text')
        .text(d => d ? generateLabel(d) : 'Max')
        // TODO: Fix horizontal position
        .attr('x', (d, i, e) => d 
          ? ((Math.log10(d) - Math.log10(xMin)) / (Math.log10(xMax) - Math.log10(xMin))) * innerWidth + MARGIN_HORIZONTAL - e[i].getBoundingClientRect().width / 2
          : innerWidth + MARGIN_HORIZONTAL - e[i].getBoundingClientRect().width / 2
        )
        .attr('y', LABEL_HEIGHT)
        .style('font-size', 11)
        .style('font-family', '"Futura", sans-serif');

    // Setting local range value
    setLocalRange(range);
  }, []);

  // Updating chart styling based on sliders
  useEffect(() => {
    _columns.current.attr('fill', (d, i, e) => (
        (i / e.length) >= (rangeValue[0] - relativeWidth / 2)
        && 
        (i / e.length) <= (rangeValue[1] - relativeWidth / 2)
      ) 
      ? ACTIVE_COLOR 
      : INACTIVE_COLOR
    );
    setLocalRange(range);
  }, [rangeValue[0], rangeValue[1]]);

  const _handleChangeEnd = (range) => onChangeEnd(range);

  const _handleChange = (index) => val => {
    clearTimeout(_timeout.current);
    const range = [...localRange];
    range[index] = parseInt(val) || 0;
    _timeout.current = setTimeout(() => _handleChangeEnd(range), 500);
    setLocalRange(range);
  }

  const _handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const range = [...localRange].sort((a, b) => a - b);
    console.log(range); 
    onChange(range);
  }

  return (
    <div className="chart-wrapper" style={{ width }}>
      <div className="chart" style={{ height }} ref={_wrapper}></div>
      <div className="slider" style={{ paddingLeft: MARGIN_HORIZONTAL, paddingRight: MARGIN_HORIZONTAL }}>
        <Slider
          value={rangeValue}
          onPureChange={_handleSliderChange}
          range
        />
      </div>
      <div className="axis" ref={_axis}></div>
      <form className="inputs" onSubmit={_handleSubmit}>
        <Input label={`Min ${label.toLowerCase()}`} value={Math.round(localRange[0])} onChange={_handleChange(0)} />
        <span className='spacer'> – </span>
        <Input label={`Max ${label.toLowerCase()}`} value={Math.round(localRange[1])} onChange={_handleChange(1)} />
        <input type="submit" value="submit" style={{ visibility: 'hidden' }}/>
      </form>
    </div>
  )
}

export default Chart;