import React from 'react';
import './_input.scss';

const Input = ({ label, value, onChange = () => {}}) => {
  const _handleChange = ({ target: { value }}) => onChange(value);
  return (
    <div className="input-wrapper">
      <span className="label">{ label }</span>
      <input type="text" value={value} onChange={_handleChange}/>
    </div>
  )
}

export default Input;