import React, { useRef, useState } from 'react';
import './_slider.scss';
/**
 * 
 * value: can be either a generic number - for regular slider, or an array containing [min, max] for range
 */
const Slider = ({ name, label, onChange = () => {}, onPureChange = () => {}, min = 0, max = 1, value = 0.5, range = false, disabled }) => {
  const wrapper = useRef(null);
  const _handleChange = index => (val) => {
    if (!range) {
      onChange({ name, value: val });
      onPureChange(val)
    } else {
      const _value = [...value];
      if (index) _value[index] = Math.max(_value[0], val);
      else _value[index] = Math.min(_value[1], val);
      _value.sort();
      onPureChange(_value);
      onChange({ name, value: _value })
    }
  }

  const val = (value) => (value - min) / (max - min);

  return (
    <div className="slider-outer-wrapper">
      <div className={`slider-input-wrapper ${disabled ? 'disabled' : ''}`}>
        <div ref={wrapper} className="line">
          <div className="highlight" style={{ 
            left: `${val(range ? Math.min(...value) : 0) * 100}%`,
            right: `${(1 - val((range ? Math.max(...value) : value))) * 100}%`
          }}
          />
          <Knob min={min} max={max} onChange={_handleChange(0)} wrapper={wrapper} value={range ? value[0] : value} label={label} />
          { range && <Knob min={min} max={max} onChange={_handleChange(1)} wrapper={wrapper} value={value[1]} label={label} /> }
        </div>
      </div>
    </div>
  )
}

const SNAPPING = 0;

const Knob = ({ label, value, wrapper, onChange, min, max }) => {
  const [isActive, setIsActive] = useState(false);
  const _handleMouseMove = ({ clientX, clientY }) => {
    if (!wrapper.current) return;
    // Getting bounding box of parent
    const { x: xOffset, y: yOffset, width, height } = wrapper.current.getBoundingClientRect();
    
    // Calculating relative positions, clamping values between 0-1, and aplying snappoints
    let x = Math.min(1, Math.max(0, ((clientX - xOffset) / width)));
    if (x < SNAPPING) x = 0;
    if (x > 1 - SNAPPING) x = 1;
    
    onChange(min + (max - min) * x)
  }

  const _handleMouseDown = ({ horizontal = false, vertical = false }) => {
    window.addEventListener('mousemove', _handleMouseMove);
    window.addEventListener('mouseup', _handleMouseUp);
    setIsActive(true);
  }
  const _handleMouseUp = () => {
    window.removeEventListener('mousemove', _handleMouseMove);
    window.removeEventListener('mouseup', _handleMouseUp);
    setIsActive(false);
  }

  const val = (value - min) / (max - min);

  return (
    <div onMouseDown={_handleMouseDown} className={`knob-wrapper ${isActive ? 'active' : ''}`} style={{ left: `${val * 100}%`}}>
      <div className="knob"></div>
    </div>
  )
}

export default Slider;
