import React, { useState } from 'react';

import Chart from './Chart';

const MOCK_DATA = ([
  {
    "count": 12,
    "label": 100,
    "log_count": 1.0791812460476249
  },
  {
    "count": 130,
    "label": 200,
    "log_count": 2.113943352306837
  },
  {
    "count": 79,
    "label": 300,
    "log_count": 1.8976270912904414
  },
  {
    "count": 63,
    "label": 400,
    "log_count": 1.7993405494535817
  },
  {
    "count": 35,
    "label": 500,
    "log_count": 1.5440680443502757
  },
  {
    "count": 31,
    "label": 600,
    "log_count": 1.4913616938342726
  },
  {
    "count": 24,
    "label": 700,
    "log_count": 1.380211241711606
  },
  {
    "count": 18,
    "label": 800,
    "log_count": 1.255272505103306
  },
  {
    "count": 13,
    "label": 900,
    "log_count": 1.1139433523068367
  },
  {
    "count": 15,
    "label": 1000,
    "log_count": 1.1760912590556813
  },
  {
    "count": 73,
    "label": 2000,
    "log_count": 1.863322860120456
  },
  {
    "count": 28,
    "label": 3000,
    "log_count": 1.4471580313422192
  },
  {
    "count": 13,
    "label": 4000,
    "log_count": 1.1139433523068367
  },
  {
    "count": 11,
    "label": 5000,
    "log_count": 1.041392685158225
  },
  {
    "count": 5,
    "label": 6000,
    "log_count": 0.6989700043360189
  },
  {
    "count": 5,
    "label": 7000,
    "log_count": 0.6989700043360189
  },
  {
    "count": 5,
    "label": 8000,
    "log_count": 0.6989700043360189
  },
  {
    "count": 1,
    "label": 9000,
    "log_count": 0
  },
  {
    "count": 3,
    "label": 10000,
    "log_count": 0.47712125471966244
  },
  {
    "count": 19,
    "label": 20000,
    "log_count": 1.2787536009528289
  },
  {
    "count": 8,
    "label": 30000,
    "log_count": 0.9030899869919435
  },
  {
    "count": 3,
    "label": 50000,
    "log_count": 0.47712125471966244
  },
  {
    "count": 1,
    "label": 60000,
    "log_count": 0
  },
  {
    "count": 2,
    "label": 70000,
    "log_count": 0.3010299956639812
  },
  {
    "count": 1,
    "label": 90000,
    "log_count": 0
  },
  {
    "count": 4,
    "label": 200000,
    "log_count": 0.6020599913279624
  },
  {
    "count": 3,
    "label": 300000,
    "log_count": 0.47712125471966244
  },
  {
    "count": 1,
    "label": 400000,
    "log_count": 0
  },
  {
    "count": 1,
    "label": 600000,
    "log_count": 0
  }
])

const input3 = [
  {
    "count": 1,
    "label": 100,
    "log_count": 0
  },
  {
    "count": 44,
    "label": 200,
    "log_count": 1.6434526764861874
  },
  {
    "count": 20,
    "label": 300,
    "log_count": 1.3010299956639813
  },
  {
    "count": 14,
    "label": 400,
    "log_count": 1.146128035678238
  },
  {
    "count": 5,
    "label": 500,
    "log_count": 0.6989700043360189
  },
  {
    "count": 7,
    "label": 600,
    "log_count": 0.8450980400142568
  },
  {
    "count": 8,
    "label": 700,
    "log_count": 0.9030899869919435
  },
  {
    "count": 5,
    "label": 800,
    "log_count": 0.6989700043360189
  },
  {
    "count": 2,
    "label": 900,
    "log_count": 0.3010299956639812
  },
  {
    "count": 6,
    "label": 1000,
    "log_count": 0.7781512503836436
  },
  {
    "count": 15,
    "label": 2000,
    "log_count": 1.1760912590556813
  },
  {
    "count": 3,
    "label": 3000,
    "log_count": 0.47712125471966244
  },
  {
    "count": 4,
    "label": 4000,
    "log_count": 0.6020599913279624
  },
  {
    "count": 6,
    "label": 5000,
    "log_count": 0.7781512503836436
  },
  {
    "count": 2,
    "label": 7000,
    "log_count": 0.3010299956639812
  },
  {
    "count": 1,
    "label": 8000,
    "log_count": 0
  },
  {
    "count": 1,
    "label": 9000,
    "log_count": 0
  },
  {
    "count": 2,
    "label": 20000,
    "log_count": 0.3010299956639812
  },
  {
    "count": 1,
    "label": 30000,
    "log_count": 0
  }
]

const App = () => {
  const [range, setRange] = useState([1000, 500000]);

  return (
    <Chart
      data={input3.map(entry => ({ x: entry.label, y: entry.log_count }))} 
      range={range}
      onChange={setRange}
      onChangeEnd={(range) => console.log(`Change ended with range: ${range}`)}
    />
  )
}

export default App;